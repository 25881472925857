.wrapper {
  // position: absolute;
  top: 50%;
  left: 45%;
  // transform: translate(-50%, -50%);
}

.ul {
  list-style: none;
  padding: 0;
}

.ul li {
  width: 50px;
  height: 50px;
  position: relative;
  background: #e59500;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ul li i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
}

.ul li.facebook {
  background: #3b5998;
}

.ul li.twitter {
  background: #00aced;
}

.ul li.instagram {
  background: #bc2a8d;
}

.ul li.google {
  background: #dd4b39;
}

.ul li.whatsapp {
  background: #4dc247;
}

.ul li.viber {
  background: rgb(192, 126, 212);
}

.ul li.tiktok {
  background: #000;
}

.ul li.facebook div.slider {
  background: #627aac;
}

.ul li.twitter div.slider {
  background: #7fd5f6;
}

.ul li.instagram div.slider {
  background: #dd94c6;
}

.ul li.google div.slider {
  background: #eea59c;
}

.ul li.whatsapp div.slider {
  background: #82d47e;
}

.ul li.viber div.slider {
  background: rgb(192, 126, 212);
}

.ul li.tiktok div.slider {
  background: #000;
}

.ul li div.slider {
  display: flex;
  justify-content: center;
}

.slider {
  content: "";
  position: absolute;
  top: 0;
  left: 51px;
  width: 0px;
  height: 50px;
  background: #eebb5c;
  border-radius: 3px;
  transition: all 0.5s 0.3s ease;
}

.slider p {
  font-family: arial;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  line-height: 50px;
  opacity: 0;
  transition: all 0.6s ease;
}

.ul li:hover .slider {
  width: 180px;
  transition: all 0.5s ease;
}

.ul li:hover .slider p {
  opacity: 1;
  transition: all 1s 0.2s ease;
}
