.container {
  padding: 0 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: end;

  .MDBCardImage {
    width: 45px;
  }
}

@media only screen and (max-width: 768px) {
  :global(.ant-form-item) {
    margin-bottom: 0 !important;
  }
}
