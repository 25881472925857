.container {
  display: flex;
  column-gap: 100px;
  flex-wrap: wrap;
  margin-top: 10px;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 20px;
    max-width: 1300px;
    justify-content: flex-start;

    li {
      cursor: pointer;

      & > div {
        max-width: 300px;
        min-width: 300px;

        img {
          max-height: 187px;
          object-fit: contain;
          width: -webkit-fill-available;
        }
      }
    }
  }

  .productImage {
    width: -moz-available;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    ul {
      justify-content: center;

      li {
        & > div {
          :global(.ant-card-body) {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .container {
    .bars {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      flex-wrap: wrap;
    }
  }
}
