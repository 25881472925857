@import "../../../../public/styles/variables";

.container {
  width: 100%;
  height: 534px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  margin-top: 13px;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  max-width: 630px;
  height: 300px;

  .context {
    color: $white;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 1.4;
    z-index: 1;

    div {
      max-width: 836px;
    }

    span {
      color: "green";
    }

    .btn {
      margin: 0 auto;
      margin-top: 40px;
    }
  }

  .bgShadow {
    background: $black;
    opacity: 0.5;
    position: absolute;
    height: 534px;
    border-radius: 20px;
    max-height: 300px;
  }
}
