.container {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  textarea {
    resize: none;
  }

  .inputContainer {
    border-radius: 10px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;
    padding: 18px 0;
    margin-top: 20px;
  }

  .uploadFileInput {
    display: none !important;
  }

  .imageReview {
    width: -webkit-fill-available;
    max-width: 466px;
    margin-top: 20px;
  }
}

.createProduct {
  :global(.ant-form-item-row) {
    display: block;
    width: 100%;
  }

  :global(.ant-form-item-label) {
    text-align: start;
    width: auto;
    max-width: fit-content;
  }

  :global(.ant-form-item-control) {
    width: 100%;
    max-width: 100%;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  .btn {
    margin-top: 20px;
    text-align: end;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    form {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
}
