.map {
  height: 400px;
  width: 100%;
}

.anyReactComponent {
  font-size: 16px;
  color: red;

  .location {
    width: 40px;
  }
}
