@import "../../../public/styles/variables";

.container {
  .contentStyle {
    position: relative;

    & > div {
      position: absolute;
      color: $white;
      font-weight: 800;
      top: 20px;
      left: 20px;
    }

    img {
      object-fit: cover;
      width: -webkit-fill-available;
    }
  }

  .services {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  h1 {
    margin-top: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 1400px) {
  .container {
    .contentStyle {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .container {
    .home {
      max-height: 200px;
    }
  }
}
