@import "../../../public/styles/variables";

.addCart {
  background: $lightBlue;
  border: 1px solid transparent;
  transition: 1s;

  &:hover {
    color: $lightBlue;
    background: $white;
    border-color: $lightBlue;
  }
}

.removeProduct {
  background: $red;
}

.editProduct {
  display: inline-block;
  background: green;
}

.btn {
  font-size: 18px;
  color: $white;
  border: none;
  padding: 10px 31px;
}

.image {
  text-align: center;
}

.priceBlock {
  margin-top: 20px;

  .card {
    border: 5px FOR;
  }

  .minusPlusBlock {
    flex-wrap: wrap;
  }
}

.comment {
  resize: unset;
}

@media only screen and (max-width: 768px) {
  .comment {
    height: 130px;
  }
}
