.container {
  max-width: 1320px;
  margin: 0px auto;
  margin-top: 50px;

  .content {
    display: flex;
    justify-content: space-evenly;
    column-gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
  }
}

.form {
  max-width: 470px;

  .fullName {
    display: flex;
    column-gap: 10px;
  }

  textarea {
    resize: none;
    height: 110px;
  }

  :global(.ant-form-item-label) {
    min-width: 180px;
    text-align: start;
  }

  :global(.ant-form-item-row) {
    display: block;
  }

  input,
  textarea {
    border-radius: 3px;
  }

  :global(.ant-form-item-control) {
    max-width: inherit;
  }

  :global(.ant-form-item) {
    margin-bottom: 10px;
  }

  .btn {
    text-align: end;
  }

  & > h2 {
    text-align: center;
  }
}

.message {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  img {
    max-width: 450px;
  }

  p {
    column-gap: 5px;
    display: flex;
    align-items: baseline;
    margin-bottom: 5px;

    span:first-child {
      padding: 5px;
      border: 1px solid;
      width: fit-content;
      border-radius: 40px;
      margin-bottom: 10px;
    }
  }

  .socialMedia {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

:global(.ant-popover-title) {
  display: none !important;
}

.bigImage {
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: 820px;
  }
}

@media only screen and (max-width: 578px) {
  .message {
    flex-direction: row;

    .socialMedia {
      display: grid;
    }

    img {
      display: none;
    }

    p {
      margin: 0;
    }
  }

  .form {
    .btn {
      margin-top: 10px;
    }

    .fullName {
      display: block;
    }
  }

  .container {
    .content {
      display: block;
      padding: 0 20px;
    }
  }
}
