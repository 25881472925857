@import "../../../public/styles/variables";

.container {
  margin-top: 100px;
}

.table {
  overflow-x: auto;
}

@media only screen and (max-width: 768px) {
  .tabs {
    display: block !important;

    :global(.ant-tabs-nav-list) {
      display: block !important;
    }

    :global(.ant-tabs-nav-wrap) {
      overflow-x: auto !important;
    }

    :global(.ant-tabs-ink-bar-animated) {
      display: none;
    }

    :global(.ant-tabs-tab-active) {
      border-bottom: 1px solid $blue;
    }

    :global(.ant-tabs-tab) {
      padding: 3px !important;
      margin-left: 40px !important;
    }
  }
}
