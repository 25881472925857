@import "../../../../public/styles/variables";

.contentStyle {
  color: $white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  button {
    border-radius: 160% !important;
    height: 20px !important;
    width: 20px !important;
  }

  button:not(:global(.slick-active)) {
    background: $blue !important;
  }

  :global(.slick-dots-bottom) {
    bottom: -5px;
  }

  :global(.slick-track) {
    display: flex;
    align-items: center;
  }

  :global(.slick-arrow) {
    top: 100%;

    &::before {
      font-size: 20px !important;
      color: #fff;
    }
  }
}
