@import "../../../../public/styles/variables";

.flagImage {
  width: 25px;
}

.navbar {
  background: $white;
  max-width: 1330px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 10px;

  .logo {
    max-width: 160px;
  }

  .nav {
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
    }

    a {
      color: $black;
      text-decoration: none;

      &:hover,
      &:focus,
      &:focus-visible,
      &:active {
        color: $blue;
        text-decoration: underline;
      }
    }

    button {
      border: none;
    }
  }

  .navLogin {
    display: flex;
    justify-content: flex-end;
  }
}

// @keyframes putDown {
//   from {
//     transform: translateY(-20px);
//   }
//   to {
//     transform: translateY(0);
//   }
// }

// .xmasHat {
//   margin-left: -40px;
//   margin-top: -9px;
//   animation: putDown 1.5s ease;
//   width: 36px;
// }

@media only screen and (max-width: 991px) {
  .navbar {
    display: none;
  }

  .nav {
    justify-content: center;
    display: grid;

    a {
      text-align: center;
      border: 1px solid $blue;
      padding: 10px 20px;
      width: 100%;
      margin-top: 5px;

      &:hover {
        color: $white !important;
        background: $blue;
      }
    }

    & > div {
      display: contents !important;
    }
  }
}



//     --------------------  Burger menu  ---------------------


#menuToggle {
  display: block;
  // position: relative;
  top: 50px;
  left: 50px;
  max-width: 100px;
  transform: translate(0px, 30%);
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
  * Make this absolute positioned
  * at the top left of the screen
  */
#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background: #fff;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
  * And let's slide it in from the left
  */
#menuToggle input:checked ~ ul {
  transform: none;
}

.burgerMenu {
  background: white;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .logo {
    max-width: 150px;
  }
  
  :global(.ant-dropdown-trigger) {
    border: none;
    background: unset;

    &:active {
      border: none;
      background: unset !important;
    }
  }
}

.flagImage {
  width: 25px;
}

@media only screen and (min-width: 991px) {
  .burgerMenu {
    display: none;
  }
}
