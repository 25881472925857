.container {
  & > h1 {
    text-align: center;
  }

  .formGroup {
    width: 100%;
  }

  .uploadFileInput {
    display: none;
  }

  .imageReview {
    position: relative;
    cursor: pointer;
    max-width: 100px;
    margin: 0 auto;

    img {
      border-radius: 20px;
    }

    span {
      position: absolute;
      display: none;
      top: 50%;
      left: 40%;
      color: red;
    }

    & > div {
      width: 100%;
      height: 100%;
      position: absolute;
      background: black;
      max-width: 100px;
      border-radius: 20px;
      opacity: 0.6;
      display: none;
    }

    &:hover {
      & > div,
      span {
        display: block;
      }
    }
  }
}
