.container {
  padding: 0 20px 20px;

  .back {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .table {
    max-width: 1000px;
    overflow-x: auto;
    margin: 0 auto;

    tr {
      cursor: pointer;
    }
  }
}
